import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import educationSVG from "../assets/svg/education.svg"

const Education = () => {
    return (
        <div>
            <Helmet>
                <title>Éducation | relais 237</title>
            </Helmet>

            <Entete name='Éducation' couleur={'btn-eco'}  icon={educationSVG}/>
            <Article categorie='Education' btnBg='eco' textColor={'text-eco'} kwSearch=''/>
        </div>
    );
};

export default Education;