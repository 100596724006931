import React from 'react';
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {dateParser2} from "./utils";

const BreakingNews = () => {
    const news = useSelector(state => state.ArticleReducer.filter((art) => art.categorie.includes('BreakingNews')))

    return (
        <div>
            <div className="border pt-2 rounded-2 bg-white">
                <p className="text-center position-relative m-0" style={{ top: "-12px" }}>
                    <span className="p-1 bg-light rounded-2 px-2 h4">
                        <i className="bi-newspaper"> </i>
                        Breaking News
                    </span>
                </p>

                <ul className="list-unstyled p-3 pt-0">
                    {news.map((news, key) => (
                        <div className='d-flex py-1' key={key}>
                            <i className='p-2 fas fa-hand-back-point-right text-success'/>
                            <span>
                                <NavLink to={"/"+ news.categorie +"/" + news.slug}
                                         className='hover-new d-md-none d-block'
                                         style={{fontSize: '1.1rem'}}>{news.titre}</NavLink>
                                <NavLink to={"/"+ news.categorie +"/" + news.slug} style={{fontSize: '1.1rem'}}
                                         className='font-weight-bold hover-new d-none d-md-block'
                                >{news.titre}
                                </NavLink>- <span style={{fontSize: '12px'}}>{dateParser2(news.date_creation)}</span>
                            </span>
                        </div>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default BreakingNews;