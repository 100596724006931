import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import societeSVG from "../assets/svg/societe.svg"

const Societe = () => {
    return (
        <div>
            <Helmet>
                <title>Société | Relais 237</title>
            </Helmet>

            <Entete name='Société' couleur={'btn-societe'}  icon={societeSVG}/>
            <Article categorie='Société' btnBg='societe' textColor={'text-societe'} kwSearch=''/>
        </div>
    );
};

export default Societe;