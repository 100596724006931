import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";

const Register = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [adresse, setAdresse] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleCreateAccount = (e) => {
        e.preventDefault()
        if (password === confirmPassword && password !== ''){
            axios.post('/api_CRUD/user/', {
                username,
                email,
                password
            }).then(res => {
                if (res.data){
                    setPassword('')
                    setUsername('')
                    setEmail('')
                    navigate('/login')
                }else {
                    console.log(false)}
            })
        }
    }

    return (
        <div>
            <Helmet>
                <title>Créer un compte | Relais 237</title>
            </Helmet>

            <div className='register'>
                <div className="container-register">
                    <p className='h1 p-3 text-center'>
                        {/*<i className='bi-person-plus-fill'> </i>*/}
                        Créer un compte
                    </p>

                    <form onSubmit={handleCreateAccount}>
                        <div className='container-label'>
                            <label className='py-2'>
                                <input type='text' value={username} className='form-control'
                                       placeholder="nom d'utilisateur"
                                       onChange={(e) => setUsername(e.target.value)}/>
                            </label>

                            <label className='py-2'>
                                <input type='email' value={email} className='form-control'
                                       placeholder='Adresse mail'
                                       onChange={(e) => setEmail(e.target.value)
                                       }/>
                            </label>

                            <label className='py-2'>
                                <input type='text' value={adresse} placeholder='Adresse'
                                       onChange={(e) => setAdresse(e.target.value)
                                       }/>
                            </label>

                            <label className='py-2'>
                                <input type={'password'} value={password} placeholder='Entrez votre mot de passe'
                                       onChange={(e) => setPassword(e.target.value)
                                       }/>
                            </label>

                            <label className='py-2'>
                                <input type={'password'} value={confirmPassword} className={'form-control'}
                                       placeholder='confirmer mot de passe'
                                       onChange={(e) => setConfirmPassword(e.target.value)
                                       }/>
                            </label>
                        </div>

                        <div className='d-flex justify-content-between mt-4'>
                            <button type='button' onClick={() => navigate('/login')}
                                    className='text-dark border-0 bg-transparent align-self-center'
                            >J'ai déjà un compte</button>
                            <button type='submit' className='btn-success border-0 rounded-4 px-4 py-2'>Créer mon compte</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;