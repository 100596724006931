import {SEARCH} from "../constants";

const SearchReducer = (state = '', action) => {
    switch (action.type) {
        case SEARCH:
            return action.payload
        default:
            return state
    }
};

export default SearchReducer;