import React, {useContext, useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import Card from "./Card";
import {useSelector} from "react-redux";
import {testContext} from "../context/context";

const Article = (props) => {
    const {categorie, btnBg, textColor, search} = props
    const Articles = useSelector(state => state.ArticleReducer)
    const kwSearch  = useSelector(state => state.SearchReducer).toLowerCase()
    const user  = useSelector(state => state.UserReducer)
    const {pertinence} = useContext(testContext)
    const [articles, setArticles] = useState([])

    useEffect(() => {
        switch (pertinence) {
            case 1:
                setArticles([...Articles].sort((a, b) => b.date_creation - a.date_creation)
                        .filter((art) => search ? art.titre.toLowerCase().includes(kwSearch): 
                        art.categorie.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")
                        .includes(categorie.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, ""))));
                break;
            case 2:
                setArticles([...Articles].sort((a, b) => b.commentaire_set.length - a.commentaire_set.length ||
                         b.likes.lenght - a.likes.lenght)
                        .filter((art) => search ? art.titre.toLowerCase().includes(kwSearch): 
                        art.categorie.normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(categorie.normalize('NFD').replace(/\p{Diacritic}/gu, ""))));
                break;
            case 3:
                setArticles([...Articles].sort((a, b) => b.vues - a.vues)
                        .filter((art) => search ? art.titre.toLowerCase().includes(kwSearch): 
                        art.categorie.normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(categorie.normalize('NFD').replace(/\p{Diacritic}/gu, ""))));
                break;
            default:
                break;
        }
    }, [pertinence, kwSearch])

    // variable de la pagination
    const [articlePerPage] = useState(12)
    const [indexInit, setIndexInit] = useState(0)
    const [indexEnd, setIndexEnd] = useState(articlePerPage)
    
    // gestion de la pagination
    const handlePageClick = async (e) => {
        const indexOffLastArticle = (e.selected+1)*articlePerPage
        const indexOffFirstArticle = indexOffLastArticle - articlePerPage
        setIndexInit(indexOffFirstArticle)
        setIndexEnd(indexOffLastArticle)
    }

    return (
        <div className='container border border-1 bg-white rounded-2 px-0'>
            <div className='row m-0'>
                {articles.slice(indexInit, indexEnd).map((article) => (
                   <Card keyID={article.id} article = {article} btnBg={btnBg} textcolor={textColor}/>
                ))}
            </div>

            { !articles.length && search ? <p className='text-center mt-3 p-5 h5'>

                Désolé <span className='text-black'>{user.length ? user['username']: null}</span>
                , aucun article correspondant à
                <span className='text-decoration-underline text-black px-1'>{kwSearch}</span> actuellement
            </p>: null }

            { !articles.length ? <p className='text-center mt-3 p-5 h5'>
                Veillez patiente un moment svp
            </p>: null }

            { !articles.length ?(
                <div className="h-100 p-5 h1 text-center text-danger">
                    <i className='fas fa-spinner fa-pulse'/>
                </div>
            ): null}

            { articles.length ?
                <ReactPaginate
                    previousLabel={'Precedent'}
                    nextLabel={'Suivant'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(articles.length/articlePerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange = {handlePageClick}
                    containerClassName={'pagination pagination-circle justify-content-center pt-4'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link '}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClasseName={'page-link'}
                    activeClassName={'active '}
                    activeLinkClassName={btnBg + ' text-light rounded-1'}
                /> : null
            }
        </div>
    );
};

export default Article;