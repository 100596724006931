import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BreakingNews from "../Component/BreakingNews";
import axios from "axios";
import {FacebookShareButton} from "react-share";
import Commentaire from "../Component/systeme_de_commentaire/Commentaire";
import {CiClock2} from "react-icons/ci";
import {BsFacebook} from "react-icons/bs";
import {FaLinkedin, FaTwitter} from "react-icons/fa6";


const BreakingNewsDetail = () => {

    const dateParser = (chaine) => {
        let newDate = new Date(chaine).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: 'numeric',
            hour: '2-digit'
        })
        return newDate
    }

    const {New} = useParams()

    const [data, setdata] = useState({})
    const [donne, setdonne] = useState([])

    const [updateState, setupdateState] = useState(0)

    const [Profiles, setProfiles] = useState({})
    const [profile, setprofile] = useState({})

    useEffect(() => {

        axios.get('/api_CRUD/News/')
            .then(res => {setdata(res.data)})
            .then(() => {
                const selectedData = Object.keys(data).map((i) => data[i])
                const SelectedData = selectedData.filter((element) => element.titre.includes(New))
                setdonne(SelectedData)
            })

        axios.get('/api_CRUD/Profile')
            .then((prof) => setProfiles(prof.data))
            .then(() => {
                const filter = Object.keys(Profiles).map((i) => Profiles[i]);
                setprofile(filter);
            });

        window.scrollTo({top: 0, behavior: "smooth"})

        setTimeout(()=>{
            if(updateState<10){
                setupdateState(updateState => updateState+=1)
            }
        }, 100)

    },[updateState])

    return (
        <div>

            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8'>

                        {donne.map((article, key) => (
                            <div key={key}>

                                <h1 className='text-black font-weight-bold'>{article.titre}</h1>


                                <p className='py-2 d-flex justify-content-between'>
                                    <span>
                                        <CiClock2/>
                                        {dateParser(article.date_creation)}
                                    </span>

                                    <span>
                                        <FacebookShareButton url={'http://127.0.0.1:8000/politique/'} hastag='#politique'>
                                            <BsFacebook/>
                                            {/*<i className="bi-facebook m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #3b5998', color: '#3b5998'}}>  </i>*/}
                                        </FacebookShareButton>
                                        <FaTwitter style={{border: '2px solid #00acee', color: '#00acee'}}/>
                                        <FaLinkedin style={{border: '2px solid #0e76a8', color: '#0e76a8'}} />
                                        {/*<i className="bi-twitter m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #00acee', color: '#00acee'}}>  </i>*/}
                                        {/*<i className="bi-linkedin m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #0e76a8', color: '#0e76a8'}}>  </i>*/}
                                    </span>
                                </p>

                                <div className='embed-responsive embed-responsive-16by9'>
                                    <img className="embed-responsive-item" src={article.image.replace("http://localhost:8000", window.location.origin)} alt={'image'} height="450" width="730"/>
                                </div>

                                <p className='pt-3'>{article.contenu}</p>
                                <hr/>

                                {/* partie commentaires */}
                                <Commentaire modele={'breakingNews'} article={article} updateState={updateState} setupdadeState={setupdateState} prof={profile} id={key}/>
                            </div>

                        ))}

                    </div>
                    <div className='col'>
                        <BreakingNews update={updateState} setupdade={setupdateState} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreakingNewsDetail;