import React, {lazy, Suspense, useEffect} from 'react';
import {NavLink, useLocation, useParams} from "react-router-dom";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import {dateParser2, slideSettingsDetails, truncateHellip, parseBody} from "../Component/utils";
import {useDispatch, useSelector} from "react-redux";
import VideoPlay from "../Component/video_player";
import Slider from 'react-slick';
import {Helmet} from "react-helmet";
import {baseURLShare} from "../index";
import {viewArticle} from "../redux/actions/view_action";
import {FaEye, FaThumbsUp} from "react-icons/fa6";

const BreakingNews = lazy(() => import("../Component/BreakingNews"))
const Commentaire = lazy(() => import("../Component/systeme_de_commentaire/Commentaire"))

const Detail = () => {
    const {key} = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const videos = useSelector(state => state.VideoReducer).slice(0, 3)
    const articles = useSelector(state => state.ArticleReducer).slice(0, 4)
    const article = useSelector(state => state.ArticleReducer.filter((art) => art.slug === key))
    const similarArticles = useSelector(state => state.ArticleReducer
        .filter((art) => art.categorie === article[0].categorie && art.id !== article[0].id)).slice(0, 10)

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        dispatch(viewArticle(article.id, article.vues))
    }, [location.pathname])

    useEffect(() => {
        dispatch(viewArticle(article.id, article.vues))
    }, [])

    return (
        <div>
            <Helmet>
                <meta name='title' content={article[0].titre}/>
                <meta name='description' content={parseBody(article[0].body, 160)}/>
                <meta name='author' content={article[0].publisher['username']}/>

                <meta property='og:site_name' content='Relais 237'/>               
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="https://relais237.net"/>
                <meta property="og:title" content={article[0].titre}/>
                <meta property="og:description" content={parseBody(article[0].body, 160)}/>
                <meta property="og:image" itemProp='image' content={article[0].image}/>
                <meta property="og:image:width" content='250'/>
                <meta property="og:image:height" content="250"/>

    
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={baseURLShare + '/' + article[0].categorie + '/' + article[0].slug}/>
                <meta property="twitter:title" content={article[0].titre}/>
                <meta property="twitter:description" content={parseBody(article[0].body, 160)}/>
                <meta property="twitter:image" content={article[0].image}/>

                <title>{article[0].titre} - Relais 237</title>
            </Helmet>

            <main className='container'>
                <div className='row detail' id='detail-begin'>
                    <div className="col-md-8 col-sm-12">
                        <div>
                            <h1 className='text-black font-weight-bold d-none d-md-block'>{article[0].titre}</h1>
                            <h1 className='text-black d-block d-md-none'>{article[0].titre}</h1>

                            <p className='py-2 m-0 d-flex justify-content-between'>
                                <div className='d-flex flex-column text-black-40'>
                                    <span>
                                        <i className="fas fa-user-alt" style={{paddingRight: '5px'}}/>
                                        {article[0].publisher['username']}
                                    </span>

                                    <span>
                                        <i className="bi-clock-fill" style={{paddingRight: '5px'}}/>
                                        {dateParser2(article[0].date_creation)}
                                    </span>
                                </div>

                                <span className='align-self-center'>
                                    <FacebookShareButton url={baseURLShare + '/' + article[0].categorie + '/' + article[0].slug} hastag='#politique'>
                                        <i className="bi-facebook m-1 p-1 p-md-3  rounded-5" style={{border: '2px solid #3b5998', color: '#3b5998'}}>  </i>
                                    </FacebookShareButton>

                                    <TwitterShareButton  url={baseURLShare + '/' + article[0].categorie + '/' + article[0].slug}>
                                        <i className="bi-twitter m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #00acee', color: '#00acee'}}>  </i>
                                    </TwitterShareButton>

                                    <WhatsappShareButton  url={baseURLShare + '/' + article[0].categorie + '/' + article[0].slug}>
                                        <i className="bi-whatsapp m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #25d366', color: '#25d366'}}>  </i>
                                    </WhatsappShareButton>

                                    <LinkedinShareButton  url={baseURLShare + '/' + article[0].categorie + '/' + article[0].slug}>
                                        <i className="bi-linkedin m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #0e76a8', color: '#0e76a8'}}>  </i>
                                    </LinkedinShareButton>
                                </span>
                            </p>

                            <div className='embed-responsiv4e embed-responsive-323'>
                                <figure className='d-flex flex-column'>
                                    <img className="embed-responsive-item" alt={article[0].image_figure} srcset={article[0].image.replace("http://localhost:8000", window.location.origin)} />
                                    <figcaption className='text-dark text-center'
                                                style={{color: '#7bcd06', zIndex: '1000', font: 'italic smaller sans-serif', padding: '3px' }}>{article[0].image_figure}</figcaption>
                                </figure>
                            </div>

                            <div className='text-end mx-3' style={{fontSize: "0.90rem"}}>
                               <FaEye className='mx-1'/>
                                {/*<i className='fas fa-eye mx-1'/>*/}
                                <span>{article[0].vues}</span>

                                <span> <i className='fas fa-pipe mx-3'/> </span>

                                 <FaThumbsUp className='mx-1'/>
                                {/*<i className='fas fa-thumbs-up mx-1'/>*/}
                                <span>{article[0].likes.length}</span>
                            </div>

                            <div id='detail-body' dangerouslySetInnerHTML={{__html: article[0].body}}/>
                            <hr className='mb-0'/>

                            {/* partie commentaires */}
                            <Suspense fallback={'...'}>
                                <Commentaire modele='article' article={article[0]}/>
                            </Suspense>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12 pt-5 px-1 px-md-3'>
                        <Suspense fallback={'...'}>
                            <BreakingNews/>
                        </Suspense>

                        <div className='mt-4 border p-1 bg-white rounded-2'>
                            <h5 className='text-center mb-1'>Articles similaire</h5>

                            <div className='justify-content-center row mb-4'>
                                <div className='w-25 bg-warning rounded-2' style={{height: '5px'}}/>
                            </div>

                            { similarArticles.map((article, key) =>
                                <div key={key} className='row mb-3'>
                                    <div className='col-5'>
                                        <div className='embed-responsive embed-responsive-16by9'>
                                            <NavLink to={'/'+article.categorie+'/'+article.slug}>
                                                <img src={article.image.replace("http://localhost:8000", window.location.origin)} alt={article.image_figure}
                                                     className='embed-responsive-item effet'/>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className='col-7 p-0 px-2'>
                                        <h6 className='text-black font-weight-bold text-culture'>
                                            <NavLink to={'/'+article.categorie+'/'+article.slug}>
                                                {truncateHellip(article.titre, 45)}
                                            </NavLink>
                                        </h6>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                <div className='mt-5'>
                    <h4 className='px-1'>Plus de contenu</h4>
                    <hr/>

                    <div className='row px-5 px-md-0 mb-4'>
                        <Slider {...slideSettingsDetails}>

                            <div className='col-12 col-lg-3 col-md-6 px-2 mb-2'>
                                <div className='embed-responsive embed-responsive-16by9'>
                                    <NavLink to={'/relais237-TV/'+videos[0].slug}>
                                        <VideoPlay src={videos[0].video} poster={videos[0].poster}/>
                                    </NavLink>
                                </div>

                                <p className='text-lowercase p-0'>{videos[0].titre}</p>
                            </div>

                            { articles.map((article, key) =>
                                <div key={key} className='col-12 col-lg-3 col-md-6 px-2 mb-2'>
                                    <div className='embed-responsive embed-responsive-16by9'>
                                        <NavLink to={'/'+article.categorie+'/'+article.slug+'#detail-begin'}>
                                            <img src={article.image.replace("http://localhost:8000", window.location.origin)} alt={article.image_figure}
                                                 className='embed-responsive-item rounded-2'/>
                                        </NavLink>
                                    </div>

                                    <p className='text-lowercase'>{article.titre}</p>
                                </div>
                            )}

                            <div className='col-12 col-lg-3 col-md-6 mb-2 px-2'>
                                <div className='embed-responsive embed-responsive-16by9'>
                                    <NavLink to={'/relais237-TV/'+videos[1].slug}>
                                        <VideoPlay src={videos[1].video} poster={videos[1].poster}/>
                                    </NavLink>
                                </div>

                                <p className='text-lowercase'>{videos[1].titre}</p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Detail;