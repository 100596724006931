import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import cultureSVG from "../assets/svg/culture.svg"

const Culture = () => {
    return (
        <div>
            <Helmet>
                <title>Culture | Relais 237</title>
            </Helmet>
            <Entete name='Culture' couleur={'btn-culture'}  icon={cultureSVG}/>
            <Article categorie='Culture'  btnBg='culture' textcolor={'text-culture'} kwSearch=''/>
        </div>
    );
};

export default Culture;