export const VIEW_ARTICLE = "VIEW_ARTICLE"
export const VIEW_VIDEO = "VIEW_VIDEO"
export const GET_VIDEOS = "GET_VIDEOS"
export const GET_USER = "GET_USER"
export const SEARCH = "SEARCH"
export const REPONDRE = "REPONDRE"
export const ADD_COMMENT_ARTICLE = "ADD_COMMENT_ARTICLE"
export const ADD_COMMENT_VIDEO = "ADD_COMMENT_VIDEO"
export const GET_AGENDAS = "GET_AGENDAS"
export const GET_ARTICLES = "GET_ARTICLES"
export const ADD_ARTICLE = "ADD_ARTICLE"
export const UPDATE_ARTICLE = "UPDATE_ARTICLE"
export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const ADD_AGENDA = "ADD_AGENDA"
export const UPDATE_AGENDA = "UPDATE_AGENDA"
export const DELETE_AGENDA = "DELETE_AGENDA"
export const ADD_VIDEO = "ADD_VIDEO"
export const UPDATE_VIDEO = "UPDATE_VIDEO"
export const DELETE_VIDEO = "DELETE_VIDEO"

export const baseURLSocket = "ws://127.0.0.1:8000/ws/"