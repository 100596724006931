import React, {lazy, Suspense} from 'react';
import {useSelector} from "react-redux";
import Card1 from "./Card1";
const PolitiqueComponent = lazy(() => import("./home_components/politique_component"))
const AgendaComponent = lazy(() => import("./home_components/agenda_component"))
const VideoComponent =  lazy(() => import("./home_components/video_component"))
const EconomieComponent =  lazy(() => import("./home_components/economie_component"))
const SocieteComponent = lazy(() => import("./home_components/societe_component"))
const CultureComponent = lazy(() => import("./home_components/culture_component"))
const SportComponent = lazy(() =>  import("./home_components/Sport_component"))
const RecentArticles = lazy(() => import("./home_components/articles_recent"))
const BreakingNews = lazy(() => import("./BreakingNews"))

const Home_components = () => {
    const articles = useSelector(state => [...state.ArticleReducer]
        .sort((a, b) => a.date_creation - b.date_creation))

    return (
        <main className='container px-md-3'>
            {/* les 3 plus recent article publier */}
            <div className='row'>
                <div className='col-md-8 col-sm-12 animation px-0 px-md-2 fade-in'>
                    {articles[0] && <Card1 article = {articles[0]}/>}
                </div>

                <div className='col row d-flex m-0 mt-1 mt-md-0 card1 justify-content-between'>
                    {articles.slice(1, 3).map((article, key) => (
                        <div key={key} className='card-responsive'>
                            <div key={key} className={`animation fade-in col-sm-6 col-md-12 mb-3 suiteUne${key}`}>
                                <Card1 article = {article} titre={true}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* fil d'actualité */}
            <section id={'recentNews'} className="row mt-4">
                <div className="col-md-8 col-sm-12 border position-relative rounded-top bg-white">
                    <RecentArticles/>
                </div>

                <section id={'breaking'} className="col-md-4 px-0 px-md-3 col-sm-12 mt-3 mt-md-0">
                    <BreakingNews/>
                </section>
            </section>


            {/* actualité politique et agenda */}
            <div className='row mt-5'>
                <section id='politique' className='col-md-8 col-sm-12 border position-relative rounded-top bg-white'>
                    <Suspense fallback={'...'}>
                        <PolitiqueComponent/>
                    </Suspense>
                </section>


                <section id={'agenda'} className='col-md-4 col-sm-12 mt-5 mt-md-0 p-0 px-md-3'>
                    <div className='position-relative border rounded-top bg-white'>
                        <Suspense fallback={'...'}>
                            <AgendaComponent/>
                        </Suspense>
                    </div>
                </section>
            </div>

            <div className='row pt-5'>
                {/* actu video */}
                <section id='video' className='col-md-4 col-sm-12 position-relative border px-0 px-md-2 rounded-3 bg-white'>
                    <Suspense fallback={'...'}>
                        <VideoComponent/>
                    </Suspense>
                </section>

                {/* actualité économique */}
                <section id='economie' className='col-md-8 col-sm-12  mt-5 mt-md-0 p-0 px-md-3'>
                    <div className='position-relative border bg-white rounded-top px-3'>
                        <Suspense fallback={'...'}>
                            <EconomieComponent/>
                        </Suspense>
                    </div>
                </section>
            </div>

            {/* Actualité de société */}
            <section id='societe' className="mt-5 row border position-relative rounded-top bg-white">
                <Suspense fallback={'...'}>
                    <SocieteComponent/>
                </Suspense>
            </section>

            {/* Actualité culturel */}
            <section id='culture' className="mt-5 row border position-relative rounded-top bg-white">
                <Suspense fallback={'...'}>
                    <CultureComponent/>
                </Suspense>
            </section>

            {/* Actualité Sportive */}
            <section id='sport' className='row mt-5 border-top position-relative border-bottom bg-white pb-4'>
                <Suspense fallback={'...'}>
                    <SportComponent/>
                </Suspense>
            </section>
        </main>
    );
};

export default Home_components;