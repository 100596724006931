import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./Styles/index.scss";
import "./Styles/components/navigation.css";
import "./Styles/components/card.css";
import "./Styles/components/logo.css";
import "./Styles/components/Model_commentaire.css";
import "./Styles/components/Ads.css";
import "./Styles/components/breaking_new.css";
import "./Styles/pages/home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// mdb & bootstrap icons
import "./assets/scss/mdb.free.scss";
import "mdb-ui-kit/js/mdb.min";
import "bootstrap-icons/font/bootstrap-icons.css";

// fontawesome
// import "./assets/web/css/all.css";
// import "./assets/web/js/all";

// redux
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import axios from "axios";

import rootReducers from "./redux/reducers";
import { getArticles } from "./redux/actions/article_actions";
import { getAgendas } from "./redux/actions/agenda_actions";
import { getVideos } from "./redux/actions/video_actions";
//import {agendaSocket, articleSocket, videoSocket} from "./redux/actions/socket_actions";
import ContextExemple from "./context/context";

export const baseURL = "https://relais237.net/api/";
// export const baseURL = "http://localhost:8000/api/";


export const baseURLShare = "https://relais237.net";
axios.defaults.baseURL = baseURL;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

const persistConfig = {
  key: "root", storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducers);
let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

//store.dispatch(articleSocket());
//store.dispatch(agendaSocket());
//store.dispatch(videoSocket());
store.dispatch(getArticles());
store.dispatch(getVideos());
store.dispatch(getAgendas());

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContextExemple>
          <App />
        </ContextExemple>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
