import {GET_USER} from "../constants";

const initialState = []

const UserReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_USER:
            return action.payload
        default:
            return state
    }
};

export default UserReducer;