import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addCommentArticle, addCommentVideo} from "../../redux/actions/commentaire_actions";
import ModelCommentaire from "./Model_commentaire";

const Commentaire = (props) => {
    const user = useSelector(state => state.UserReducer)
    const dispatch = useDispatch()
    const article = props.article
    const type = props.modele
    const [email, setEmail] = useState('')
    const [commentaire, setCommentaire] = useState('')

    // ces variables vont permettre a l'utilisateur d'augmenter le nombre de commentaire
    const [nombreCommentaire, setNombreCommentaire] = useState(3)

    const handleSubmitComment = (e) => {
        e.preventDefault()
        if (user.length && commentaire && email) {
            const formData = new FormData()

            switch (type) {
                case 'article':
                    formData.append('email', user.email);
                    formData.append('contenu', commentaire);
                    formData.append('article', article.id);
                    formData.append('user', user.id);
                    dispatch(addCommentArticle(formData))
                    break;
                case 'article_video':
                    formData.append('email', user.email);
                    formData.append('contenu', commentaire);
                    formData.append('article_video', article.id);
                    formData.append('user', user.id);
                    dispatch(addCommentVideo(formData))
                    break;
                default:
                    break;
            }
            setCommentaire('')
        } else if (commentaire && email) {
            switch (type) {
                case 'article':
                    const data = {email, contenu:commentaire, article:article.id, user: 1}
                    dispatch(addCommentArticle(data))
                    break;
                case 'article_video':
                    const dataVideo = {email, contenu:commentaire, article_video:article.id, user: 1}
                    dispatch(addCommentVideo(dataVideo))
                    break;
                default:
                    break;
            }
            // setEmail('')
            setCommentaire('')
        }

        document.getElementById('champ_comment').textContent = ""
    }

    const handleMoreComment = () => {
        const btn = document.getElementById('plusCommentaire')
        if (article.commentaire_set.length > nombreCommentaire) {
            setNombreCommentaire(nombreCommentaire + 1)
            if (article.commentaire_set.length - 1  <= nombreCommentaire) {
                btn.textContent='Moins de commentaires'
            } else {
                btn.textContent='Plus de commentaires'
            }
        } else {
            btn.textContent='Plus de commentaires'
            setNombreCommentaire(3)
        }
    }

    return (
        <div>
            {/* Bouton de commentaires et de likes */}
            <div className='d-flex justify-content-between mb-2 mt-1' style={{fontSize:'1.05rem'}}>
                {article.commentaire_set.length ?
                    <button id='plusCommentaire' className='rounded-3 pb-0 border-0 bg-dark text-white'
                            onClick={() => handleMoreComment()}>Plus de commentaire</button>:
                    "Aucun commentaire"
                }

                <div>
                    <button className='mx-2 border-0 bg-transparent'>
                    <span className='px-2'>
                        <span className='px-1'>{article.commentaire_set.length}</span>
                        <i className='fas fa-comments text-dark'/>
                    </span>
                    </button>

                    <button className='border-0 bg-transparent'> {article.likes.length}
                        <i className='mx-1 fas fa-thumbs-up text-dark'/>
                    </button>
                </div>
            </div>

            {/* commentaire de l'article */}
            { article.commentaire_set.length ?
                <div id={'list_comment'}>
                    <div className='w-100'>
                        { article.commentaire_set.slice(-nombreCommentaire).map((comment) => (
                            <ModelCommentaire keyId={comment.id} commentaire={comment} article={article}
                                               type={type} NbreCom={nombreCommentaire}/>
                            )
                        ).reverse()}
                    </div>
                </div>: null
            }

            {/* formulaire de commentaire */}
            <p className='px-2 m-0 mt-1 text-black'>Laissez un commentaire</p>
            <form className='px-2' onSubmit={ (e) => handleSubmitComment(e)}>
                { user.length ? null:
                    <input type='email' className='form-control mb-3' placeholder='entrer votre email' value={email}
                           onChange={(e) => {setEmail(e.target.value)}}/>
                }

                <textarea className='form-control' id='champ_comment' placeholder='entrer votre commentaire' value={commentaire}
                           onChange={(e) =>{setCommentaire(e.target.value)}}/>

                <div className='text-end'>
                    <button className='bg-primary border-0 rounded-4 text-white mt-3 px-3 py-1' type='submit'>Commenter</button>
                </div>
            </form>
        </div>
    );
};

export default Commentaire;