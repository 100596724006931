import HomeComponents from "../Component/home_components";
import React from "react";
import {Helmet} from "react-helmet";


const Home = () => {
    return (
        <div className='home'>
            <Helmet>
                <title>Relais 237</title>
                <link rel="icon" href="static/img/favicon.png" type="image/x-icon"></link>
            </Helmet>

            <HomeComponents />
        </div>
    )
}

export default Home