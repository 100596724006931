import React from "react";

export const isEmpty = (value) => {
    return (
      value === undefined || value === null ||
      (typeof value === "object" && Object.keys(value).length === 0)||
      (typeof value === 'string' && value.trim().length === 0)
    );
};

export const dateParser = (chaine) => {
        return new Date(chaine).toLocaleDateString("fr-FR", {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            second: 'numeric'
        })
    }

export const dateParser2 = (chaine) => {
    return new Date(chaine).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: 'numeric',
        hour: '2-digit'
    })
}

export const textTruncate = (chaine, taille) => {
    let text = '';
    for (let i=0; i <= taille; i++){
        text += chaine[i]
    }
    if ((text[taille] !== ' ')){
        while (text[taille] !== ' '){
            taille += 1;
            text += chaine[taille];
        }
    }
    text += '...'
    return text
}

export const dateParserDM = (chaine) => {
    return new Date(chaine).toLocaleDateString("fr-FR", {
        month: "long",
        day: 'numeric',
    })
}

export const dateParserAgendaDW = (chaine) => {
    return new Date(chaine).toLocaleDateString("fr-FR", {
        weekday: "short",
        day: 'numeric',
    })
}

export const dateParserAgendaM = (chaine) => {
    return new Date(chaine).toLocaleDateString("fr-FR", {
        month: "short",
    })
}

export const truncateHellip = (str, n) => {
    if(str !== undefined && str !== null){
        if (str.length <= n) {return str;}
        const subString = str.substr(0, n-1)
        return (subString.substr(0, subString.lastIndexOf(" ")) + " ...")
    }
    
}

export const parseBody = (body, len= 230) => {
    const tempBody = document.createElement('DIV');
    tempBody.innerHTML = body;
    return truncateHellip(tempBody.innerText, len)
}

export function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
      <div
          className={className + ' rounded-circle'}
          style={{...style, display: 'block', background: 'blue'}}
          onClick={onClick}
      />
    );
}

export function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
      <div
          className={className + ' rounded-circle'}
          style={{...style, display: 'block', background: 'blue'}}
          onClick={onClick}
      />
    );
}

export const slideSettingsDetails = {
        dots: true,
        dotsClass: "slick-dots2",
        infinite: true,
        autoplay: false,
        autoplaySpeed: 6000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        rtl: true,
        pauseOnHover: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    };

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;
  const preTexte = 'il y à '

  if(interval > 1) {
      return preTexte + Math.floor(interval) + ' ans';
  }
  interval = seconds / 2592000;
  if(interval > 1) {
      return preTexte + Math.floor(interval) + ' mois';
  }
  interval = seconds / 86400;
  if(interval > 1) {
      return preTexte + Math.round(interval) + ' jours';
  }
  interval = seconds / 3600;
  if (interval > 1) {
      return preTexte + Math.round(interval) + ' h';
  }
  interval = seconds / 60;
  if (interval > 1) {
      return preTexte + Math.round(interval) + ' min';
  }
  if (interval < 1) {
      return "à l'instant";
  }
}