import React, {createContext, useState} from 'react';

export const testContext = createContext(null);

const ContextExemple = (props) => {
    const [info] = useState('salut mes amies')
    const [pertinence, setPertinence] = useState(1)

    const changePertinence = (index) => setPertinence(index)

    return (
        <testContext.Provider value={{info, pertinence, changePertinence}}>
            {props.children}
        </testContext.Provider>
    );
};

export default ContextExemple;