import React from 'react';

const SuspenseLoading = () => {
    return (
        <div className="p-5 m-5 h2 text-center text-danger">
            <i className='fas fa-spinner fa-pulse'/>
        </div>
    );
};

export default SuspenseLoading;