import {ADD_COMMENT_VIDEO, ADD_VIDEO, DELETE_VIDEO, GET_VIDEOS, REPONDRE, UPDATE_VIDEO} from "../constants";

const initialState = []
const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEOS:
            return action.payload

        case ADD_VIDEO:
            return [action.payload, ...state]

        case UPDATE_VIDEO:
            return state.map(video => {
                if (video.id === action.payload.id){
                    return action.payload
                } else return video
            })

        case DELETE_VIDEO:
            return state.filter(video => video.id !== action.payload)

        case ADD_COMMENT_VIDEO:
            return state.map((video) => {
                if (video.id === action.payload.article) {
                    return {
                        ...video,
                        commentaire_set: [...video.commentaire_set, {...action.payload, 'reponsecommentaire_set': []}]
                    }
                } else {
                    return video
                }
            })

        case REPONDRE:
            if (action.payload.type ===  'article_video') {
                return state.map((video) => {
                    if (video.id === action.payload.article) {
                        return {
                            ...video,
                            commentaire_set: video.commentaire_set.map((commentaire) => {
                                if (commentaire.id === action.payload.data.commentaire) {
                                    return {
                                        ...commentaire,
                                        reponsecommentaire_set: [...commentaire.reponsecommentaire_set, action.payload.data]
                                    }
                                } else return commentaire
                            })
                        }
                    } else return video
                })
            } else return state

        default:
            return state
    }
};

export default VideoReducer;