import {
    ADD_AGENDA,
    DELETE_AGENDA,
    GET_AGENDAS,
    UPDATE_AGENDA
} from "../constants";

const initialState = []

const AgendaReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGENDAS:
            return action.payload;

        case ADD_AGENDA:
            return [action.payload, ...state]

        case UPDATE_AGENDA:
            return state.map(event => {
                if (event.id === action.payload.id){
                    return action.payload
                } else return event
            })

        case DELETE_AGENDA:
            return state.filter(event => event.id !== action.payload)

        default:
            return state;
    }
};

export default AgendaReducer;