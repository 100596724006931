import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import sportSVG from "../assets/svg/sport.svg"

const Sport = () => {
    return (
        <div>
            <Helmet>
                <title>Sport | Relais 237</title>
            </Helmet>

            <Entete name='Sports' couleur={'btn-sport'}  icon={sportSVG}/>
            <Article categorie='Sport' btnBg='sport' textcolor={'text-sport'} kwSearch=''/>
        </div>
    );
};

export default Sport;