import React, {useContext} from 'react';
import {Helmet} from "react-helmet";
import {testContext} from "../context/context";


const Contact = () => {
    const {info} = useContext(testContext)

    return (
        <div>
            <Helmet>
                <title>Contact - Relais 237</title>
            </Helmet>

            <div className='container'>
                <h1>A propos</h1>
                <p>lorem*140 {info}</p>
                <i className='fas fa-address-book fa-spin'/>
            </div>
        </div>
    );
};

export default Contact;