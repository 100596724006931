import React, {useState} from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {dateParser2} from "../utils";

const ModelRepComment = (props) => {
    const reponse = props.reponse
    const art = props.art
    const cle = props.cle
    const [IsEditingResp, setIsEditingResp] = useState(false)
    const [EditedComment, setEditedComment] = useState('')
    const user = useSelector(state => state.UserReducer)

    return (
        <div className='d-flex' >

            <p className='px-3 py-2 h5'>
                { 1 === 2 ?
                    <img src={user.image_Profile} alt={'profile-'+user.username} width={30} className='rounded-circle border'/>
                    : <i  className='fas fa-circle-user h3' />
                }
            </p>

            <div className='row border-bottom border-2 mb-3' style={{borderBottomLeftRadius: '5px'}}>
                <div className='d-flex justify-content-between'>
                    <span>{reponse.email}</span>

                    <span className='text-black-50 d-none d-md-inline'>
                        <button className='border-0 bg-transparent' style={{transform: "rotateY(180deg)"}}>
                            <i className='bi-reply'> </i>
                        </button>
                        {art.email}
                    </span>

                    <span className='text-black-50 d-none d-md-inline'
                          style={{fontSize: '12px'}}>{dateParser2(reponse.date_modification)}
                    </span>
                </div>

                {/* Edition des reponses de commentaires */}
                { IsEditingResp ?
                    <div className='p-1'>
                        <form onSubmit={(e) => {
                            e.preventDefault()

                            axios.put('http://127.0.0.1:8000/api_CRUD/Reponse_Commentaire/'+reponse.id+'/', {
                                email: reponse.email,
                                reponse: EditedComment,
                                commentaire: art.id,
                                user: reponse.user
                            })

                            setIsEditingResp(false)
                        }
                        }>
                            <textarea className='form-control' autoFocus onChange={(e) => {
                                setEditedComment(e.target.value)}
                            } defaultValue={reponse.reponse}
                            ></textarea>

                            <div className='d-flex justify-content-end'>
                                <input className='btn' type='submit' value='valider'/>
                            </div>
                        </form>
                    </div>:
                    <p className='px-1 px-md-4 py-1 m-0 text-black'>
                        {reponse.reponse}
                    </p>
                }

                <div className='d-flex justify-content-end position-relative'>
                    {
                        user === art.email?
                            <>
                                <span onClick={() => {
                                    document.getElementById('prive'+cle).classList.toggle('d-none')
                                }
                                }>
                                    <i className='bi-three-dots'> </i>
                                </span>

                                <div className='d-none position-absolute d-flex flex-column' id={'prive'+cle}
                                     style={{top: '-170%'}}>
                                    {/* bouton d'editions des reponses */}
                                    <span className='text-primary'
                                       style={{cursor: 'pointer'}} onClick={() => {
                                        setIsEditingResp(true)
                                    }}>
                                        <i className='bi-pencil-square'> </i>
                                        {/*editer*/}
                                    </span>

                                    {/* bouton de supression des reponses */}
                                    <span className='text-danger' style={{cursor: 'pointer'}} onClick={() => {
                                        // eslint-disable-next-line no-restricted-globals
                                        confirm('vouler vous supprimer ?')
                                        axios.delete('http://127.0.0.1:8000/api_CRUD/Reponse_Commentaire/'+reponse.id+'/')
                                    }}>
                                        <i className='bi-trash'> </i>
                                        {/*supprimer*/}
                                    </span>
                                </div>

                            </>:null
                    }

                </div>
            </div>
        </div>
    );
};

export default ModelRepComment;