import {
    ADD_ARTICLE,
    ADD_COMMENT_ARTICLE,
    DELETE_ARTICLE,
    GET_ARTICLES,
    REPONDRE,
    UPDATE_ARTICLE,
    VIEW_ARTICLE
} from "../constants";

const initialState = []

const ArticleReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ARTICLES:
            return action.payload

        case ADD_ARTICLE:
            return [action.payload, ...state]

        case UPDATE_ARTICLE:
            return state.map(article => {
                if (article.id === action.payload.id){
                    return action.payload
                } else return article
            })

        case DELETE_ARTICLE:
            return state.filter(article => article.id !== action.payload)

        case ADD_COMMENT_ARTICLE:
            return state.map((article) => {
                if (article.id === action.payload.article) {
                    return {
                        ...article,
                        commentaire_set: [...article.commentaire_set, {...action.payload, 'reponsecommentaire_set': []}]
                    }
                } else {
                    return article
                }
            })

        case REPONDRE:
            if (action.payload.type ===  'article') {
                return state.map((article) => {
                    if (article.id === action.payload.article) {
                        return {
                            ...article,
                            commentaire_set: article.commentaire_set.map((commentaire) => {
                                if (commentaire.id === action.payload.data.commentaire) {
                                    return {
                                        ...commentaire,
                                        reponsecommentaire_set: [...commentaire.reponsecommentaire_set, action.payload.data]
                                    }
                                } else return commentaire
                            })
                        }
                    } else return article
                })
            } else return state

        case VIEW_ARTICLE:
            return state.map((article) => {
                if (article.id === action.payload.id) {
                    return {
                        ...article,
                        vues: action.payload.vues
                    }
                } else return article
            })
        default:
            return state
    }
};

export default ArticleReducer;