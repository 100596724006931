import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import technoSVG from "../assets/svg/techno.svg"

const Technologie = () => {
    return (
        <div>
            <Helmet>
                <title>Technologie | Relais 237</title>
            </Helmet>

            <Entete name='Technologie' couleur={'btn-pol'} icon={technoSVG}/>
            <Article categorie='Technologie' btnBg='pol' textcolor={'text-pol'} kwSearch=''/>
        </div>
    );
};

export default Technologie;