import axios from "axios";
import {GET_VIDEOS} from "../constants";

export const getVideos = () => {
    return (dispatch) => {
        return axios.get('videos/')
            .then((res) => {
                if (typeof(res.data) === 'object'){
                    dispatch({type: GET_VIDEOS, payload: res.data})
                }
            })
            .catch((err) => console.log(err))
    }
}