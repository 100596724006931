import React from 'react';
import {NavLink} from "react-router-dom";
import {dateParser2, parseBody} from "./utils";
import {useDispatch} from "react-redux";
import {viewArticle} from "../redux/actions/view_action";
import {FaClock, FaComment, FaEye, FaHeart} from "react-icons/fa6";

const Card = (props) => {
    const {article, btnBg, textColor, keyID} = props
    const dispatch = useDispatch()

    return (
        <div key={keyID} className='card mb-3 mt-2 px-0 px-md-2 col-12 col-md-6 col-lg-4 shadow-0 animation fade-in' >
            <div className=''>
                <div className='embed-responsive embed-responsive-16by9 bg-image hover-zoom card-header p-0'
                style={{background: "rgba(199,203,201,0.3)"}}>
                    <NavLink to={'/'+article.categorie+'/'+article.slug} className='rounded-3 '
                            onClick={() => {dispatch(viewArticle(article.id, article.vues))}}>
                        <img className='embed-responsive-item' src={article.image.replace("http://localhost:8000", window.location.origin)} width='500' alt={article.imageFigure}/>
                    </NavLink>
                </div>

                <div className='card-body p-2' style={{background: "rgba(199,203,201,0.12)"}}>
                    <div className='d-flex justify-content-between'>
                        <p className='p-0 m-0' style={{fontSize: '12px'}}>
                            {/*<i className='bi-clock'>  </i>*/}
                            <FaClock/>
                            {dateParser2(article.date_creation)}
                        </p>

                        <p className='p-0 m-0' style={{fontSize: '12px'}}>
                            { !article.publisher['image_Profile'] ?
                                <i className='bi-person-circle px-1'/>:
                                <img src={article.publisher['image_Profile']} alt='icon'/>
                            }
                            <span>{article.publisher['username']}</span>
                        </p>
                    </div>

                    <h5 className={'m-0 text-black d-block d-md-none font-weight-bold py-1 ' + textColor}> {article.titre} </h5>
                    <h5 className={'m-0 text-black d-md-block d-none py-1 ' + textColor}> {article.titre} </h5>

                    <p className='m-0 text-black-40'>{parseBody(article.body, 190)}</p>
                </div>

                <div className={'card-footer py-1 p-0 rounded-0 ' + btnBg}>
                    <NavLink to={'/'+article.categorie+'/'+article.slug} onClick={() => {dispatch(viewArticle(article.id, article.vues))}}
                             className={'d-flex justify-content-between px-2 text-white'}>
                        <span>Lire la suite</span>

                        <div className='d-flex align-self-center' style={{fontSize: '0.90em'}}>
                            <div>
                                <i className='fas fa-eye text-white-50 px-1'/>
                                <FaEye/>
                                {/*<span>{article.vues}</span>*/}
                            </div>

                            <div className='mx-3'>
                                <FaHeart className='fas fa-heart text-white-50 px-1'/>
                                {/*<i />*/}
                                <span>{article.likes.length}</span>
                            </div>

                            <div>
                                <FaComment className='fas fa-comment text-white-50 px-1'/>
                                {/*<i />*/}
                                <span>{article.commentaire_set.length}</span>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default Card;