import axios from "axios";
import {VIEW_ARTICLE, VIEW_VIDEO} from "../constants";

export const viewArticle = (id, nbrVue) => {
    return (dispatch) => {
        return axios
            .patch('/articles/' + id + '/', {"vues": nbrVue + 1})
            .then((res) => {
                console.log(res.data)
                dispatch({type: VIEW_ARTICLE, payload: res.data})
            })
            .catch((err) => console.log(err))
    }
}

export const viewVideo = (id, nbrVue) => {
    return (dispatch) => {
        return axios
            .patch('/videos/' + id + '/', {"vues": nbrVue + 1})
            .then((res) => {
                console.log(res.data)
                dispatch({type: VIEW_VIDEO, payload: res.data})
            })
            .catch((err) => console.log(err))
    }
}