import React from 'react';
import VideoPlayer from "react-video-js-player";

const VideoPlay = (props) => {
    const {src, poster, autoPlay} = props

    let player = {}

    const state = {
        video: {src, poster,}
    }

    const onPlayerReady = (Player) => {
      player = Player;
    }

    const onVideoSeeking = (duration) => {
        console.log("Video seeking: ", duration)
    }

    return (
        <div>
            <VideoPlayer
                controls={true}
                autoplay={!!autoPlay}
                src={state.video.src}
                poster={state.video.poster}
                onReady={(play) => onPlayerReady(play)}
                onSeeking={(duration) =>  onVideoSeeking(duration)}
                className='embed-responsive-item'
            />
        </div>
    );
};

export default VideoPlay;