import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import economieSVG from "../assets/svg/economie.svg"

const Economie = () => {
    return (
        <div>
            <Helmet>
                <title>Économie | relais 237</title>
            </Helmet>
            <Entete name='Économie' couleur={'btn-eco'} icon={economieSVG}/>
            <Article categorie='Economie' btnBg='eco' textColor={'text-eco'} kwSearch=''/>
        </div>
    );
};

export default Economie;