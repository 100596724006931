import React, {useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Helmet} from "react-helmet";

const Login = () => {
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div>
            <Helmet>
                <title>Connexion | Relais 237</title>
            </Helmet>

            <div className='container pt-3'>
                <div className='row justify-content-center pt-2'>
                    <div className="bg-body p-3 p-md-5 m-5 rounded-3" style={{width: '500px'}}>
                        <div className='h1 p-3 text-center'>
                            {/*<i className='bi-person-fill text-black'> </i>*/}
                            Connexion
                        </div>
                        <form className='' onSubmit={(event) => {
                            event.preventDefault()
                            axios.post('/auth/', {username, password})
                                .then((data) => {
                                    if (data.data.token) {
                                        dispatch({type: 'userConnect', payload:username})
                                        document.querySelector('.form').textContent = ''
                                        event.preventDefault();
                                        navigate('/')
                                    }
                                })
                                .catch((err) => err? document.getElementsByClassName('form').classList += 'error': null)
                        }}>

                            <div className='py-2'>
                                <label htmlFor='username' className='form-label text-black'>nom d'utilisateur</label>

                                <input type='text' className='form-control form  ' id='username' onChange={(event) => {
                                    setusername(event.target.value)
                                }}/>
                            </div>

                            <div className='py-2'>
                                <label htmlFor='password' className='text-black'>mot de passe</label>

                                <input type='password' className="form-control form" id='password' width='100' onChange={(event) => {
                                    setpassword(event.target.value)
                                }}/>
                            </div>

                            <div className='text-end'>
                                <button type='submit' className='btn-success mt-3 rounded-4 px-3 py-2 border-0'>Se connecter</button>
                            </div>

                            <button type='button' className='text-danger border-0 bg-transparent'>Mot de passe oublié ?</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;