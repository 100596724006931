import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import politiqueSVG from "../assets/svg/politique.svg"

const Politique = () => {
    return (
        <div className='position-relative'>
            <Helmet>
                <title>Politique | Relais 237</title>
            </Helmet>

            <Entete name = 'Politique' couleur={'btn-pol'} icon={politiqueSVG}/>
            <Article categorie='politique' btnBg='pol' textcolor={'text-pol'} kwSearch=''/>
        </div>
    );
};

export default Politique;