import axios from "axios";
import {REPONDRE} from "../constants";

export const repondreCommentaire = (data, type, article) => {
    return (dispatch) => {
        return axios
            .post('reponses_commentaire/', data)
            .then((res) => {
                dispatch({type: REPONDRE, payload: {"data": res.data, "type": type, "article": article}})
            })
            .catch((err) => console.log(err))
    }
}