import React from 'react';
import Article from "../Component/Article";
import Entete from "../Component/Entete";
import {Helmet} from "react-helmet";
import santeSVG from "../assets/svg/sante.svg"

const Sante = () => {
    return (
        <div>
            <Helmet>
                <title>Santé | Relais 237</title>
            </Helmet>

            <Entete name='Santé' couleur={'btn-pol'}  icon={santeSVG}/>
            <Article categorie='Sante' btnBg='pol' textcolor={'text-pol'} kwSearch=''/>
        </div>
    );
};

export default Sante;