import React from 'react';
import {NavLink} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {timeSince, truncateHellip} from "./utils";

const Card1 = (props) => {
    const {article, titre} = props
    const navigate = useNavigate()

    return (
        <>
            <div id={'card1'} className="bg-image hover-zoom" style={{cursor: 'pointer'}}
                 onClick={() => {navigate('/'+article.categorie+'/'+ article.slug)}}>
                <div className='embed-responsive embed-responsive-16by9 '>
                    <img className='embed-responsive-item h-100' src={article.image.replace("http://localhost:8000", window.location.origin)} alt={article.image_figure}/>

                    <div className="mask" style={{backgroundColor: "rgba(128,128,136,0.04)"}}>
                        <NavLink to={'/'+article.categorie} className="position-absolute badge p-1 m-1 ml-2 top-0 text-white cat">
                            {article.categorie}
                        </NavLink>

                        <div className="position-absolute bottom-0 text-end px-1 rounded-3" style={{right: '0px'}}>
                            <span className="text-white text-center m-2 px-1 d-md-block d-none"
                                  style={{textShadow: "0px 2px black"}}>
                                {titre? truncateHellip(article.titre, 70): article.titre}
                            </span>

                            <span className="text-white text-end m-1 px-1 d-md-none d-block"
                                  style={{textShadow: "0px 2px black"}}>
                                {!titre? truncateHellip(article.titre, 75): timeSince( new Date(article.date_modification))}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Card1;