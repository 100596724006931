import axios from "axios";
import {ADD_COMMENT_ARTICLE, ADD_COMMENT_VIDEO} from "../constants";

export const addCommentArticle = (data) => {
    return (dispatch) => {
        return axios
            .post('commentaires/', data)
            .then((res) => {
                dispatch({type: ADD_COMMENT_ARTICLE, payload: res.data})
            })
            .catch((err) => console.log(err))
    }
}

export const addCommentVideo = (data) => {
    return (dispatch) => {
        return axios.post('commentaires/', data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                dispatch({type: ADD_COMMENT_VIDEO, payload: res.data})
            })
            .catch((err) => console.log(err))
    }
}