import React, {useEffect, useState} from 'react';
import Entete from "../Component/Entete";
import {NavLink} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {useSelector} from "react-redux";
import VideoPlay from "../Component/video_player";
import {Helmet} from "react-helmet";
import tvSVG from "../assets/svg/tv.svg"
import {FaEye} from "react-icons/fa6";
import {BiPlay} from "react-icons/bi";

const Video = () => {
    const videos = useSelector(state => state.VideoReducer)
    // variable de la pagination
    const [videosPerPage] = useState(3)
    const [indexInit, setIndexInit] = useState(0)
    const [indexEnd, setIndexEnd] = useState(videosPerPage)
    const [currentVideos, setcurrentVideos] = useState(videos.slice(indexInit, indexEnd))
    const [updateState, setUpdateState] = useState(0)

    useEffect(() => {
        // setcurrentArticle(videos.slice(indexInit, indexEnd))

    },[])

    // gestion de la pagination
    const handlePageClick = async (e) => {
        const indexOffLastArticle = (e.selected+1) * videosPerPage
        const indexOffFirstArticle = indexOffLastArticle - videosPerPage
        setIndexInit(indexOffFirstArticle)
        setIndexEnd(indexOffLastArticle)
        setcurrentVideos(videos.slice(indexInit, indexEnd))
        setUpdateState(updateState => updateState+=1)
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <div>
            <Helmet>
                <title>Relais 237 TV</title>
            </Helmet>

            <Entete name='Relais 237 TV' couleur={'btn-culture'} icon={tvSVG}/>

            <div className='container'>
                <div className='row border pt-3'>
                    {currentVideos.map((video, key) => (
                        <div key={key} className='col-12 col-md-4 pb-3'>
                            <div className='position-relative'>
                                <div className='embed-responsive embed-responsive-16by9 bg-warning poster-video'>
                                    <NavLink to={'/relais237-TV/'+video.categorie+ '/' +video.slug}>
                                        <img className='embed-responsive-item' src={video.poster.replace("http://localhost:8000", window.location.origin)} width='500' alt={video.titre}/>

                                        <div className='center-absolute d-flex align-items-center justify-content-center'>
                                            {/*<i className="bi-play-fill "/>*/}
                                             <BiPlay className='mx-1 text-white h4 m-0'/>
                                        </div>

                                        <span className='position-absolute py-1 py-md-0 px-3 px-md-2 rounded-2 m-2 bg-dark text-white bottom-0'
                                        style={{right: 0, fontSize: "0.75em"}}>00 : 00</span>
                                    </NavLink>
                                </div>
                                <span className='position-absolute m-2 badge bg-warning text-white top-0'>{video.categorie}</span>
                            </div>

                            <h6> <NavLink to={'/relais237-TV/'+video.categorie+ '/' +video.slug}>{video.titre}</NavLink> </h6>
                        </div>
                    ))}
                </div>

                <div>
                    <ReactPaginate
                        previousLabel={'Precedent'}
                        nextLabel={'Suivant'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(videos.length/videosPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange = {handlePageClick}
                        containerClassName={'pagination pagination-circle justify-content-center pt-4'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClasseName={'page-link'}
                        activeClassName={'active' }
                        activeLinkClassName={'societe text-white rounded-1'}
                    />
                </div>
            </div>
        </div>
    );
};

export default Video;