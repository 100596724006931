import axios from "axios";
import {GET_ARTICLES} from "../constants";

export const getArticles = () => {
    return (dispatch) => {
        return axios.get('articles/')
            .then((res) => {
                if (typeof(res.data) === 'object'){
                    dispatch({type: GET_ARTICLES, payload: res.data})
                } 
            })
            .catch((err) => console.log(err))
    }
}
