import React, {lazy, Suspense} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SuspenseLoading from "./Component/suspense-loading";
import Home from "./pages/Home";
import Politique from "./pages/Politique";
import Economie from "./pages/Economie";
import Education from "./pages/Education";
import Technologie from "./pages/Technologie";
import Societe from "./pages/Societe";
import Sante from "./pages/Sante";
import Culture from "./pages/Culture";
import Sport from "./pages/Sport";
import Video from "./pages/Video";
import Search from "./pages/Search";
import BreakingNewsDetail from "./pages/BreakingNewsDetail";
import AgendaDetail from "./pages/AgendaDetail";
import DetailVideo from "./pages/detailVideo";
import Detail from "./pages/Detail";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
const PageIndex = lazy(() => import("./pages/pageIndex"));

const App = () => {
    return (
        <div style={{background: "rgba(26,25,25,0.02)"}}>
            <BrowserRouter>
                <Suspense fallback={<SuspenseLoading/>}>
                    <Routes>
                        <Route path={"/"} element={<PageIndex/>}>
                            <Route path={'/'} element={<Home/>} />
                            <Route path='politique'  element={<Politique/>}/>
                            <Route path='economie'  element={<Economie/>}/>
                            <Route path='education'  element={<Education/>}/>
                            <Route path='technologie'  element={<Technologie/>}/>
                            <Route path='societe'  element={<Societe/>}/>
                            <Route path='sante'  element={<Sante/>}/>
                            <Route path='culture'  element={<Culture/>}/>
                            <Route path='sport'  element={<Sport/>}/>
                            <Route path='relais237-TV'  element={<Video/>}/>
                            <Route path='search/:KWord' element={<Search/>} />
                            <Route path='BreakingNews/:New'  element={<BreakingNewsDetail/>}/>
                            <Route path='Agenda/:events' element={<AgendaDetail/>} />
                            <Route path='relais237-TV/:categorie/:slug'  element={<DetailVideo/>}/>
                            <Route path=':cat/:key'  element={<Detail/>}/>
                            <Route path='contact'  element={<Contact/>} />
                        </Route>

                        <Route path='/login'  element={<Login/>} />
                        <Route path='/register'  element={<Register/>}/>
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </div>
    );
};

export default App;