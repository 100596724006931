import {combineReducers} from "redux";
import ArticleReducer from "./article_reducer";
import UserReducer from "./user_reducer";
import AgendaReducer from "./agenda_reducer";
import VideoReducer from "./video_reducer";
import SearchReducer from "./search_reducer";

export default combineReducers({
    ArticleReducer,
    VideoReducer,
    AgendaReducer,
    UserReducer,
    SearchReducer
})