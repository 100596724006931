import {NavLink, useParams} from "react-router-dom";
import BreakingNews from "../Component/BreakingNews";
import Commentaire from "../Component/systeme_de_commentaire/Commentaire";
import {dateParser2, parseBody, slideSettingsDetails} from "../Component/utils";
import {useSelector} from "react-redux";
import VideoPlay from "../Component/video_player";
import React from "react";
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import {baseURL} from "../index";
import {BsClock} from "react-icons/bs";

const DetailVideo = () => {
    const {slug} = useParams()
    const videos = useSelector(state => state.VideoReducer.filter((vid) => vid.slug !== slug))
    const video = useSelector(state => state.VideoReducer.filter((vid) => vid.slug === slug))

    return (
        <div>
            <Helmet>
                <title>{video[0].titre} - Relais 237</title>
            </Helmet>

            <div className='container'>
                <div className='row'>
                    <div className="col-12 col-md-8">
                        <h1 className='text-black font-weight-bold'>{video[0].titre}</h1>

                        <p className='py-2 d-flex justify-content-between'>
                            <span>
                                <BsClock/>
                                {/*<i className="bi-clock-fill">  </i>*/}
                                {dateParser2(video[0].date_creation)}
                            </span>

                            <span className='align-self-center'>
                                <FacebookShareButton url={baseURL + '/relais237-TV/' + video[0].categorie + '/' + video[0].slug} hastag='#politique'>
                                    <i className="bi-facebook m-1 p-1 p-md-3  rounded-5" style={{border: '2px solid #3b5998', color: '#3b5998'}}>  </i>
                                </FacebookShareButton>

                                <TwitterShareButton  url={baseURL + '/relais237-TV/' +video[0].categorie + '/' + video[0].slug}>
                                    <i className="bi-twitter m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #00acee', color: '#00acee'}}>  </i>
                                </TwitterShareButton>

                                <WhatsappShareButton  url={baseURL + '/relais237-TV/' + video[0].categorie + '/' + video[0].slug}>
                                    <i className="bi-whatsapp m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #25d366', color: '#25d366'}}>  </i>
                                </WhatsappShareButton>

                                <LinkedinShareButton  url={baseURL + '/relais237-TV/' + video[0].categorie + '/' + video[0].slug}>
                                    <i className="bi-linkedin m-1 p-1 p-md-3 rounded-5" style={{border: '2px solid #0e76a8', color: '#0e76a8'}}>  </i>
                                </LinkedinShareButton>
                            </span>
                        </p>

                        <div className='embed-responsive embed-responsive-16by9 mb-2'>
                            <VideoPlay src={video[0].video} poster={video[0].poster} autoPlay={true}/>
                        </div>

                        <div id='' dangerouslySetInnerHTML={{__html: video[0].body}}/>
                        <hr/>

                        {/* partie commentaires */}
                        <Commentaire modele={'article_video'} article={video[0]}/>

                    </div>

                    <div className='col pt-4 p-0'>
                        <div className='mb-5 mb-md-5'>
                            {videos.map((video) =>
                                <div className='row mb-3 px-2'>
                                    <div className='col-12 col-md-7'>
                                        <div className='embed-responsive embed-responsive-16by9 poster-video'>
                                            <NavLink to={'/relais237-TV/' + video.categorie+ '/' + video.slug}>
                                                <img className='embed-responsive-item' src={video.poster.replace("http://localhost:8000", window.location.origin)} width='500' alt={video.titre}/>

                                                <div className='center-absolute d-flex align-items-center justify-content-center'>
                                                    <i className="bi-play-fill text-white h4 m-0"/>
                                                </div>

                                                <span className='position-absolute py-md-0 px-2 rounded-1 m-2 bg-dark text-white bottom-0'
                                                      style={{right: 0, fontSize: "0.75em"}}>00 : 00</span>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-5'>
                                        <h6 className='text-black font-weight-bold text-culture'>
                                            <NavLink to={'/relais237-TV/' + video.slug}>{video.titre}</NavLink>
                                        </h6>
                                        <p>{parseBody(video.body, 60)}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <BreakingNews/>
                    </div>
                </div>
            </div>

             <div className='mt-5'>
                    <h4 className='text-center text-black'>Les plus regardé</h4>

                    <hr/>

                    <div className='row px-5 px-md-0 mb-4'>
                        <Slider {...slideSettingsDetails}>
                            { videos.map((video, key) =>
                                <div key={key} className='col-12 col-lg-3 col-md-6 px-2 mb-2'>
                                    <div className='embed-responsive embed-responsive-16by9'>
                                        <NavLink to={'/relais237-TV/'+video.slug}>
                                            <VideoPlay src={video.video} poster={video.poster}/>
                                        </NavLink>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
        </div>
    );
};

export default DetailVideo;