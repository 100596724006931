import React, {useState} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {timeSince} from "../utils";
import {repondreCommentaire} from "../../redux/actions/reponses_action";
import ReponseCommentaire from "./Reponse_commentaire";

const ModelCommentaire = (props) => {
    const key  = props.keyId;
    const commentaire = props.commentaire;
    const article = props.article;
    const type = props.type;
    const [emailResp, setEmailResp] = useState('')
    const [contenu, setContenu] = useState('')
    const [reponseCommentaire, setReponsecommentaire] = useState('')
    const [IsEditing, setIsEditing] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(state => state.UserReducer)

    const handleSendReponse = (e) => {
        e.preventDefault()
        let data
        if (user.length) {
            data = {
                email: user.email,
                reponse: reponseCommentaire,
                commentaire: commentaire.id,
                user: user.id
            }
        } else {
            data = {
                email: emailResp,
                reponse: reponseCommentaire,
                commentaire: commentaire.id,
            }
        }
        dispatch(repondreCommentaire(data, type, article.id))
        setReponsecommentaire('')
        document.getElementById('form_comment'+key).classList.add('d-none')
    }

    return (
        <div key={key} id={'model_commentaire'} className='pb-2 d-flex'>
            {/* gestion de l'affichage des images d'utilisateur du commentateur */}
            <p className='px-1 pt-2'>
                { commentaire.user && commentaire.user['image_Profile'] ?
                    <img src={commentaire.user['image_Profile']} alt={'image-'+key} width={50} className='rounded-circle border'/>
                    : <i  className='bi-person-circle h3' />
                }
            </p>

            {/* Commentaire de l'article */}
            <div className='' id='cardComment'>
                <div className='mx-2 d-flex justify-content-between text-black-40'>
                    <p className='m-0'>{commentaire.user['username'] ? commentaire.user['username']: commentaire.email}</p>

                    <div className='d-flex'>
                        <p className='text-black-50 m-0 align-self-center mx-2' style={{fontSize: '13px'}}
                        >{timeSince(new Date(commentaire.date_modification))}</p>

                        <button className='border-0 bg-transparent'>
                            <i className='bi-three-dots' style={{fontSize: '13px'}}/>
                        </button>
                    </div>
                </div>

                <div>
                    {/* gestion de l'édition de commentaires */}
                    { IsEditing ?
                        <div className='p-1'>

                            <form onSubmit={(e) => {
                                e.preventDefault()

                                axios.put('/api_CRUD/Commentaire/' + commentaire.id+'/', {
                                    email: commentaire.email,
                                    contenu,
                                    commentaire: article.id,
                                    user: user.id
                                }).catch((error) => console.log(error))
                                setIsEditing(false)
                            }
                            }>
                                <textarea className='form-control' autoFocus defaultValue={commentaire.contenu} onChange={(e) => setContenu(e.target.value)}></textarea>

                                <div className="d-flex justify-content-end">
                                    <input type={'submit'} value={'valider'} className='btn'/>
                                </div>

                            </form>

                        </div>:
                        <p className='mx-2 mt-0 mb-1 text-black'>{commentaire.contenu}</p>
                    }

                    {/*  gestion des différents bouton: repondre éditer et autres */}
                    <div className='mainPart px-2'   >
                        <div className='d-flex justify-content-between'>
                            <div>
                                {/* bouton repondre */}
                                <button className='text-black-40 font-weight-bold m-0 p-0 border-0 bg-transparent'
                                   onClick={() => {
                                       if(1 === 1){
                                           document.getElementById('form_comment'+key).classList.toggle('d-none')
                                       }else{
                                           document.getElementById('form_comment'+key).classList.remove('d-none')
                                           document.getElementById('form_comment'+key).classList.add('d-none')
                                       }

                                       for (let i = 0; i < article.commentaire_set.length; i++) {

                                       }
                                   }
                                   }>Répondre</button>

                                {/* afficher les boutons éditer et supprimer seulement si c'est le commentaire de cet utilisateur */}
                                { user.email === commentaire.email?
                                    <>
                                        {/* bouton d'édition */}
                                        <p className='text-primary px-1'
                                           style={{cursor: 'pointer'}}
                                           onClick={() => {
                                               setIsEditing(true)
                                           }}>
                                            <i className='bi-pencil-square'> </i>
                                        </p>

                                        {/* Bouton de suppression */}
                                        <p className='text-danger px-1'
                                           style={{cursor: 'pointer'}} onClick={() => {
                                            axios.delete('/api_CRUD/Commentaire/'+commentaire.id+'/').catch((err) => console.log(err))
                                        }}>
                                            <i className='bi-trash'> </i>
                                        </p>
                                    </>
                                    :null
                                }
                            </div>

                            {/* masquer ou afficher les reponses de commentaire d'un commentaire */}
                            <div id={'btnDisplayComment'+key} className='d-flex text-black-50' style={{cursor: 'pointer'}}
                                 onClick={() => {
                                     for (var i=0; i < article.commentaire_set.length; i++){
                                         if(i === key){
                                             document.getElementById('reponses'+i).classList.toggle('d-none')
                                             document.getElementById('icobtn'+i).classList.toggle('icobtn')
                                             document.getElementById('btnDisplayComment'+i).classList.toggle('DisplayReponse')
                                         }else if(2===3){
                                             document.getElementById('reponses'+i).classList.remove('d-none')
                                             document.getElementById('reponses'+i).classList.add('d-none')
                                             document.getElementById('icobtn'+i).classList.remove('icobtn')
                                             document.getElementById('btnDisplayComment'+i).classList.remove('DisplayReponse')
                                         }
                                     }
                                 }}>
                                <span className='text-black'> {commentaire.reponsecommentaire_set.length} </span>

                                <i className='bi-chevron-expand align-self-center h4 m-0'/>
                            </div>
                        </div>


                        {/*formulaire de reponse des commentaire*/}
                        <form className='px-1 d-none' id={'form_comment'+key}
                              onSubmit={(e) => handleSendReponse(e)}>
                            { !user.id?
                                <>
                                    <input type='email'
                                           className='form-control'
                                           placeholder={'entrer votre email'}
                                           onChange={(e) => {
                                               setEmailResp(e.target.value)
                                           }}/>
                                    <br/>
                                </> : null
                            }
                            <textarea className='form-control' placeholder={'entrer votre commentaire'} value={reponseCommentaire}
                                      onChange={(e) => {
                                          setReponsecommentaire(e.target.value)
                                      }}>
                            </textarea>

                            <div className='d-flex justify-content-end py-2'>
                                <input className='border-0 bg-primary rounded-2 text-white px-3 py-1' type={'submit'} value='Envoyer'/>
                            </div>
                        </form>

                        {/*/!* Section de reponse aux commentaire *!/
                        <ReponseCommentaire keyID={key} art={commentaire}/>
                        */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelCommentaire;