import React, {useState} from 'react';
import Entete from "../Component/Entete";
import {useDispatch} from "react-redux";
import Article from "../Component/Article";
import {search} from "../redux/actions/search_actions";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import searchSVG from "../assets/svg/searchengin.svg"

const Search = () => {
    // stoke les mots que l'utilisateur entre dans le champ de recherche
    const [keyWordSearch, setKeyWordSearch] = useState('')
    // va permettre de stoker des donnes avec redux
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {KWord}  = useParams()

    return (
        <div>
            <Helmet>
                <title>Recherche | Relais 237</title>
            </Helmet>

            <Entete name={'Résultat de la recherche pour: '} keyWord={KWord} icon={searchSVG}>
                {/* champ de recherche des utilisateurs */}
                <div className="my-2 text-center d-block d-md-none align-self-center" id='containerForm'>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        dispatch(search(keyWordSearch))
                        navigate('/search/'+keyWordSearch)
                    }}>
                        <div className="d-flex justify-content-center">
                            <input className="form-control bg-transparent text-black border" id="form" type="search"
                                   placeholder='Chercher ...' style={{width: "190px"}}
                                   onChange={(e) => {setKeyWordSearch(e.target.value)}}
                            />
                            <button type="submit" className="mx-2 btn-primary border rounded-4 text-center">
                                <span className='h6'>Chercher</span>
                            </button>
                        </div>
                    </form>
                </div>
            </Entete>

            <Article categorie='' search={true} btnBg='culture' textcolor={'text-eco'} kwSearch={KWord}/>
        </div>
    );
};

export default Search;