import React from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const AgendaDetail = () => {

    const {events} = useParams()

    const evenements = useSelector(state => state.Agenda)
    const evenement = evenements.filter((elem) => elem.date.includes(events))
    return (
        <div>
            <main className='container'>
                {
                    evenement.map((element) => (
                        <div className='row'>
                            <p>{element.titre}</p>
                        </div>
                    ))
                }
                <p>{events}</p>
            </main>
        </div>
    );
};

export default AgendaDetail;