import axios from "axios";
import {GET_AGENDAS} from "../constants";

export const getAgendas = () => {
    return (dispatch) => {
        return axios.get('/agendas/')
            .then((res) => {
                if (typeof(res.data) === 'object'){
                    dispatch({type: GET_AGENDAS, payload: res.data})
                }
            })
            .catch((err) => console.log(err))
    }
}