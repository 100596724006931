import React, {useContext} from 'react';
import {testContext} from "../context/context";
import {FaBars} from "react-icons/fa6";

const Entete = (props) => {
    const {name, keyWord, icon} = props
    const {pertinence, changePertinence} = useContext(testContext)

    return (
        <div className='container d-flex justify-content-between border bg-white mb-3 mt-md-2 mt-0 rounded-2 py-2 py-md-3'>
            <div className='h3 mb-0 align-content-center'>
                <div className='d-flex'>
                    <img width='50' height={30} src={icon} alt={'svg-'+name} />
                    <h4 className='p-0 m-0 text-black align-self-center pt-1'>
                        <span>{name}</span>
                        {keyWord ? <span className='text-primary text-decoration-underline'>{keyWord}</span>: null}
                    </h4>
                </div>

                <div className='mx-5'>
                    {props.children}
                </div>
            </div>

            <div className='align-self-center'>
                <button type="button" className="text-black border-0 bg-transparent" data-mdb-toggle="dropdown" aria-expanded="false">
                    <span className='text-black'>
                        {pertinence === 1? 'Date': pertinence===2? 'Pertinence': pertinence===3? 'Nbre de vues': null}
                    </span>
                    <FaBars className='mx-2'/>
                    {/*<i className='fas fa-bars-filter px-2'/>*/}
                </button>

                <ul className="dropdown-menu dropdown-menu-end">
                    <li><button onClick={() => changePertinence(1)} className="dropdown-item">Date</button></li>
                    <li><button onClick={() => changePertinence(2)} className="dropdown-item">Pertinence</button></li>
                    <li><button onClick={() => changePertinence(3)} className="dropdown-item">Nombre de vues</button></li>
                </ul>
            </div>
        </div>
    );
};

export default Entete;